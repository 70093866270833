@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../../fonts/Poppins-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../fonts/Poppins-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../../fonts/Poppins-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../../fonts/Poppins-Bold.ttf') format('truetype');
}

html,
body {
  min-height: 100%;
  height: 100%;
  font-family: $poppins, 'sans-serif';
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.custom-file-input:focus ~ .custom-file-label,
textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
}

a.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.7;
}

.remove-property {
  color: inherit;
  text-decoration: none;
}

.custom-checkbox {
  .custom-control-input {
    z-index: 1;
  }
  .custom-control-label {
    display: flex;
    align-items: center;
    &::before {
      border-radius: 0 !important;
      border: 1px solid $blue;
    }
  }
}

.custom-control-input:checked {
  & ~ .custom-control-label::before {
    color: #fff;
    background-color: $blue;
    border-color: $blue;
  }
}

::placeholder {
  color: $blue-50;
  @extend p;
  letter-spacing: 0.34px;
}

.border-bottom {
  border-bottom: 1px solid #acbadf;
}

.w-100 {
  .form-control.vue-tags-input {
    max-width: 100% !important;
  }
}
