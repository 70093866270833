.horizontal.timeline {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 25px;
  margin-left: auto;
  margin-right: auto;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 6px;
    background-color: #f7c22d;
  }

  .steps {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .step {
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      transform: translateY(8px);
      padding: 0.33em;
      height: 6px;
      width: 6px;
      box-sizing: content-box;
      color: #f7c22d;
      background-color: #f7c22d;
      border-radius: 50%;
      z-index: 500;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    section {
      position: absolute;
      margin-bottom: 100px;
      color: #000;
    }
  }
}
