$min-default-size : 8;
$max-default-size : 88;

@for $i from $min-default-size through $max-default-size{
	@if($i%8 == 0){
		// MARGIN
		.m-#{$i} { margin: #{$i}px !important; }
		.mt-#{$i} { margin-top: #{$i}px !important; }
		.mb-#{$i} { margin-bottom: #{$i}px !important; }
		.mr-#{$i} { margin-right: #{$i}px !important; }
		.ml-#{$i} { margin-left: #{$i}px !important; }
		.mx-#{$i} { margin-left: #{$i}px !important; margin-right: #{$i}px !important; }
		.my-#{$i} { margin-top: #{$i}px !important; margin-bottom: #{$i}px !important; }
		// PADDING
		.p-#{$i} { padding: #{$i}px !important; }
		.pt-#{$i} { padding-top: #{$i}px !important; }
		.pb-#{$i} { padding-bottom: #{$i}px !important; }
		.pr-#{$i} { padding-right: #{$i}px !important; }
		.pl-#{$i} { padding-left: #{$i}px !important; }
		.px-#{$i} { padding-left: #{$i}px !important; padding-right: #{$i}px !important; }
		.py-#{$i} { padding-top: #{$i}px !important; padding-bottom: #{$i}px !important; }
	}
}