$poppins        : 'Poppins';

$choco          : #A35638;
$orange         : #FF7315;
$yellow         : #F8C301;
$yellow-darken  : #F0BB00;
$yellow-lighten : #FFD210;
$green          : #02AF6C;
$green-darken   : #00A764;
$green-lighten  : #11BE7B;
$pink           : #CC0066;
$red            : #BF2F30;
$red-darken     : #B72728;
$red-lighten    : #CE3E3F;
$blue           : #253B78;
$blue-sea       : #46B3E6;
$blue-darken    : #1D3370;
$blue-80        : #7488BD;
$blue-50        : #ACBADF;
$blue-20        : #E2E8F9;
$black          : #141414;
$black-50       : #848587;
$grey           : #C3C4C5;
$grey-50        : #ADB3C4;
$grey-20        : #E6E6E6;
$white          : #FFFFFF;
$danger         : #dc3545;

$transition     : 0.4s all;

// size chart
$mobile         : 480px;
$tablet         : 736px;
$netbook        : 980px;
$notebook       : 1280px;
$large          : 1690px;