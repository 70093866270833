.twm-table {
    &--scroll {
        overflow-x: auto;
    }
    &--width {
        min-width: 2200px;
    }
}

.twm-dashboard {
    &__most {
        &-number {
            width: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &-name {
            width: 70%;
            display: flex;
            align-items: center;
        }
        &-total {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }
}

.twm__chart {
    width: 100% !important;
    height: 248px;
    canvas {
        width: 100% !important;
        height: 248px;
        margin-top: 100px;
    }
}

.twm-list {
    padding: 0;
    &__child {
        list-style-type: none;
    }
    &__card {
        background-color: $white;
    }
    &__button {
        color: $black;
        background-color: $white;
        border-color: $white;
        &:hover {
            color: $blue;
            background-color: $white;
            border-color: $white;
        }
        &:focus {
            box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5) !important;
        }
        &:active {
            color: $blue !important;
            background-color: $white !important;
            border-color: $white !important;
        }
    }
}