.loader-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 999;
  opacity: 0.7;
  cursor: pointer;
  .loader,
  .loader:before,
  .loader:after {
    border-radius: 50%;
  }
  .loader {
    color: $blue-darken;
    font-size: 11px;
    position: absolute;
    left: calc(50% - 40px);
    top: calc(50% - 40px);
    width: 80px;
    height: 80px;
    box-shadow: inset 0 0 0 8px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    &:before,
    &:after {
      position: absolute;
      content: '';
    }
    &:before {
      width: 41.6px;
      height: 81.6px;
      background: white;
      border-radius: 81.6px 0 0 81.6px;
      top: -0.8px;
      left: -0.8px;
      -webkit-transform-origin: 40.8px 40.8px;
      transform-origin: 40.8px 40.8px;
      -webkit-animation: load2 2s infinite ease 1.5s;
      animation: load2 2s infinite ease 1.5s;
    }
    &:after {
      width: 41.6px;
      height: 81.6px;
      background: white;
      border-radius: 0 81.6px 81.6px 0;
      top: -0.8px;
      left: 39.2px;
      -webkit-transform-origin: 0.8px 40.8px;
      transform-origin: 0.8px 40.8px;
      -webkit-animation: load2 2s infinite ease;
      animation: load2 2s infinite ease;
    }
  }
  @-webkit-keyframes load2 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load2 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
